// utilsモジュールからuaCheckとmq関数をインポート
// import { mq } from "./modules/utils";
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay,EffectFade } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import axios from 'axios';

import { Howl, Howler } from 'howler';
import {isNumber} from "stylelint-order/utils/validateType.js";

// プレーヤーを初期化する関数
// プレーヤーを初期化する関数
function initializePlayer(element) {
  const mp3Url = element.dataset.mp3;
  const sound = new Howl({
    src: [mp3Url],
    html5: true
  });
  const Black = element.classList.contains('black')

  // プレーヤーのUI
  const playerContainer = document.createElement('div');
  playerContainer.style.display = 'flex';
  playerContainer.style.flexDirection = 'column';
  playerContainer.style.alignItems = 'center';
  playerContainer.style.padding = '20px 20px 10px';
  playerContainer.style.marginTop = '20px';
  playerContainer.style.border = Black ? '' : '1px solid #000';
  playerContainer.style.borderRadius = Black ? '' :'10px';
  playerContainer.style.backgroundColor = Black ? '#000000' : '#f0f0f0';
  playerContainer.style.width = '100%';
  playerContainer.style.color = Black ? '#FFFFFF' : '';

  const buttonContainer = document.createElement('div');
  buttonContainer.style.display = 'flex';
  buttonContainer.style.justifyContent = 'center';


  const playButton = document.createElement('button');
  playButton.innerHTML = '<svg height="36px" width="36px" version="1.1" viewBox="0 0 36 36"><use class="ytp-svg-shadow" xlink:href="#ytp-id-76"></use><path class="ytp-svg-fill" d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z" id="ytp-id-76"></path></svg>';
  playButton.style.marginRight = '10px';
  playButton.onclick = () => {
    sound.play();
  };

  const pauseButton = document.createElement('button');
  pauseButton.innerHTML = '<svg height="36px" width="36px" version="1.1" viewBox="0 0 36 36"><use class="ytp-svg-shadow" xlink:href="#ytp-id-101"></use><path class="ytp-svg-fill" d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z" id="ytp-id-101"></path></svg>';
  pauseButton.onclick = () => {
    sound.pause();
  };

  buttonContainer.appendChild(playButton);
  buttonContainer.appendChild(pauseButton);

  const progressBar = document.createElement('input');
  progressBar.type = 'range';
  progressBar.min = '0';
  progressBar.max = '100';
  progressBar.value = '0';
  progressBar.style.width = '90%';
  progressBar.style.marginBottom = '10px';

  const timeContainer = document.createElement('div');
  timeContainer.style.display = 'flex';
  timeContainer.style.justifyContent = 'space-between';
  timeContainer.style.width = '90%';
  timeContainer.style.marginBottom = '10px';

  const elapsedTime = document.createElement('span');
  elapsedTime.innerText = '0:00';

  const totalTime = document.createElement('span');
  totalTime.innerText = '0:00';

  timeContainer.appendChild(elapsedTime);
  timeContainer.appendChild(totalTime);

  sound.on('play', () => {
    requestAnimationFrame(updateProgress);
    totalTime.innerText = formatTime(sound.duration());
  });

  function updateProgress() {
    const progress = (sound.seek() / sound.duration()) * 100;
    progressBar.value = progress;
    elapsedTime.innerText = formatTime(sound.seek());
    if (sound.playing()) {
      requestAnimationFrame(updateProgress);
    }
  }

  function formatTime(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  playerContainer.appendChild(timeContainer);
  playerContainer.appendChild(progressBar);
  playerContainer.appendChild(buttonContainer);

  element.appendChild(playerContainer);
}


//micromodal
// import MicroModal from 'micromodal';


// 後で使用するためのbody要素への参照
const BODY = document.getElementsByTagName('body');

// リサイズイベントを効率よく処理するためのデバウンス関数
/*const resize = () => {
  // デバウンス用の変数を初期化
  let timeoutID = null;
  const delay = 300;

  // ウィンドウリサイズのイベントリスナー
  window.addEventListener("resize", () => {
    // matchHeightFuncを即座に実行
    matchHeightFunc(true);
    // デバウンスのために前のタイムアウトをクリア
    clearTimeout(timeoutID);
    // matchHeightFuncの呼び出し率を制限するために新しいタイムアウトを設定
    timeoutID = setTimeout(() => {
      matchHeightFunc();
    }, delay);
  }, false);
};
resize();*/

// 高さを揃える対象のクラス名の配列
/*const targetClasses = [
  'js-matchHeight-1',
  'js-matchHeight-2',
  'js-matchHeight-3',
  'js-matchHeight-4',
  'js-matchHeight-5',
  'js-matchHeight-6',
];*/

// 要素の高さを揃える関数
/*
const matchHeight = (className, remove = false) => {
  // 指定されたクラスの要素を取得
  const elements = document.getElementsByClassName(className);
  let heights = [];

  if (!remove) {
    // elements内の要素の高さをheights配列にプッシュ
    for (const element of elements) {
      heights.push(element.offsetHeight);
    }

    // heights配列から最大の高さを決定
    const tallest = Math.max(...heights);

    // 全ての要素の高さを最大値に設定
    for (const element of elements) {
      element.style.height = `${tallest}px`;
    }
  } else {
    // 全ての要素のheightスタイルをautoにリセット
    for (const element of elements) {
      element.style.height = 'auto';
    }
  }
};

// 任意の遅延で高さを揃えるための関数
const matchHeightFunc = (remove = false, delay = 200) => {
  if (mq('pc')) {
    // PCメディアクエリで高さを固定または固定解除
    targetClasses.forEach((className) => {
      if (remove) {
        matchHeight(className, true);
      } else {
        setTimeout(() => {
          matchHeight(className);
        }, delay);
      }
    });
  } else {
    // 非PCメディアクエリで高さの固定を解除
    targetClasses.forEach((className) => {
      matchHeight(className, true);
    });
  }
};
*/

const hbgFunc = () =>{
  const header = document.querySelector('.js-header')
  const hbg = document.querySelector('.js-hbg')
  const closeHbg = document.querySelector('.js-closeHbg')

  if(!hbg) return

  hbg.addEventListener('click', function(e) {
    e.preventDefault()
    header.classList.toggle('hbg-open')

  }, false);

  closeHbg.addEventListener('click', function(e) {
    header.classList.remove('hbg-open')
  }, false);


}

const scrollBar = () =>{
  const scrollArea = document.getElementById('js-scroll-area')
  if(!scrollArea) return
  new SimpleBar(scrollArea,{
    autoHide: false,//スクロールバーを常に表示
    scrollbarMaxSize: 140　 //つまみの部分の最大サイズを60pxに指定 デフォルトは0で無制限らしい
  });
}

const preloadLink = (url) => {
  // プリロードするリソースを指定する<link>要素を作成
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  link.as = 'document';

  // 既に同じURLのプリロードが存在しないことを確認
  if (!document.head.querySelector(`link[rel="preload"][href="${url}"]`)) {
    // <head>内に<link>要素を追加
    document.head.appendChild(link);
  }
}
const customTransitionFunc = () =>{
  // すべてのリンク要素を取得
  const links = document.querySelectorAll('a');

// 各リンク要素に対してイベントリスナーを設定
  links.forEach(function(link) {
    link.addEventListener('click', function(event) {
      // リンクがページ内リンクである場合、何もせずに終了
      if ( this.getAttribute('href').indexOf('#') !== -1 ) return
      if ( this.getAttribute('target') !== null ) return

      // デフォルトのリンク動作を阻止
      event.preventDefault();

      // 特定の処理を行う関数
      BODY[0].classList.remove('open');

      // ページ遷移
      setTimeout(()=>{
        window.location.href = this.href;
      },180)
    });

    link.addEventListener('mouseover', function() {
      if (this.getAttribute('href').startsWith('#')) return
      preloadLink(this.href);
    });
  });


}

const originalImage = () =>{
  const Images = document.querySelectorAll('.js-originalImage')

  Images.forEach((image)=>{

    const src = image.dataset.originalimage
    const ext = image.dataset.extension
    const img = new Image()

    if(!src) return
    // console.log(image.dataset.originalimage)
    img.src = `${src + ext}`

    // console.log(img)


    img.onload = function () {
      image.setAttribute('srcset',`${src + ext}`)
    }



  })


}
const pathname = location.pathname;

if(
    pathname.indexOf('/en/policy/') !== -1 ||
    pathname.indexOf('/en/guide/') !== -1 ||
    pathname.indexOf('/en/event/') !== -1
){
  document.location = '/en/'
}

const buildHtml = data => {
  const container = document.getElementById('products');

  data.forEach(product => {
    const productElement = document.createElement('li');
    productElement.innerHTML = `
                <figure class="figure">
                  ${generateImageTags(product)}
                  <figcaption class="figcaption">
                    <h3 class="goods-title">
                     ${product.productName}
                    </h3>
                    <p class="price"><b>${formatPrice(product.price)}円</b><span class="tax">(税込)</span></p>
                  </figcaption>
                </figure>
                `;
    // <a href="${product.url}" target="_blank" className="hover"></a>
    container.appendChild(productElement);
  });



};

const buildNews = (data,newsArea) => {


  data.forEach(info => {
    const productElement = document.createElement('dl');
    const date = new Date(info.data);
    // 年、月（+1）、日を取得して2桁で固定
    const year  = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day   = date.getDate().toString().padStart(2, '0');
    // フォーマットして結果を表示
    const formattedDate = `${year}.${month}.${day}`;

    productElement.innerHTML = `
                  <dt class="lh18">${formattedDate}</dt>
                  <dd>
                    <p class="lh18">
                     ${ info.url ? `<a href="${info.url}" class="hover text-link underline" ${ info.tab ? `target="_blank"` : ''}>${info.text}</a>` : info.text }
                    </p>
                  </dd>
                `;
    // <a href="${product.url}" target="_blank" className="hover"></a>
    newsArea.prepend(productElement);
  });



};


function formatDate() {
  const date = new Date()
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}`;
}
const Now = formatDate()
const generateImageTags = product => {
  let imagesHtml = '';
  const formattedId = String(product.id).padStart(2, '0');
  console.log(formattedId)
  if(product.imageCount > 1){

    for (let i = 1; i <= product.imageCount; i++) {
      const imageName = `img-goods${formattedId}-${i}.jpg?v=${Now}`;
      imagesHtml += `<div class="swiper-slide"><img src="../img/goods/${imageName}" alt="${product.productName} Image ${i}"></div>`;
      // imagesHtml += `<div class="swiper-slide"><a href="${product.url}" target="_blank" class="hover"><img src="../img/goods/${imageName}?v=20240326" alt="${product.productName} Image ${i}"></a></div>`;
    }

    SwiperFunc(formattedId)

    return `
      <div class="swiper-wrap">
        <div class="swiper slider-${formattedId} mb-30">
          <div class="swiper-wrapper">${imagesHtml}</div>
          <div class="swiper-button-prev prev-${formattedId}"></div>
          <div class="swiper-button-next next-${formattedId}"></div>
        </div>
      
        <div class="swiper-pagination pagination-${formattedId}"></div>
      </div>
  `;

  }else{

    const imageName = `img-goods${formattedId}-1.jpg`;
    imagesHtml += `<div class="mb-30"><img src="../img/goods/${imageName}" alt="${product.productName} Image"></div>`;
    // imagesHtml += `<div class="mb-30"><a href="${product.url}" target="_blank" class="hover"><img src="../img/goods/${imageName}" alt="${product.productName} Image"></a></div>`;

    return imagesHtml;
  }


};

const suppressZero1 = str => {
  //略
  return str.replace(/^0+/, '');
};

const SwiperFunc = (num,time = 600) =>{

  const shiftTime = isNaN(Number(num)) ? 0 : suppressZero1(num) * 50 + time
  console.log(num)
  const options = num === "kv" ? {
    modules: [Navigation, Pagination,Autoplay,EffectFade],
    effect: 'fade', // フェードモードにする（デフォルトは 'slide'）
    fadeEffect: {
      crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
    },
    // Optional parameters
    loop: true,
    autoplay: {
      delay: isNaN(Number(num)) ? 3000 : 4000,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    speed: isNaN(Number(num)) ? 1200 : 1800,
    slidesPerView: 1,

    // If we need pagination
    pagination: {
      el: `.pagination-${num}`,
      clickable: true
    },

    // Navigation arrows
    navigation: {
      nextEl: `.next-${num}`,
      prevEl: `.prev-${num}`,
    },

  } : {
    modules: [Navigation, Pagination],
    // Optional parameters
    loop: true,
    // autoplay: {
    //   delay: isNaN(Number(num)) ? 3000 : 4000,
    // },
    speed: isNaN(Number(num)) ? 1200 : 1800,
    slidesPerView: 1,

    // If we need pagination
    pagination: {
      el: `.pagination-${num}`,
      clickable: true
    },

    // Navigation arrows
    navigation: {
      nextEl: `.next-${num}`,
      prevEl: `.prev-${num}`,
    },

  }


  setTimeout(()=>{

    const swiper = new Swiper(`.slider-${num}`, options );

  },shiftTime)


}



// 価格をフォーマットする関数
const formatPrice = price => {
  return new Intl.NumberFormat('ja-JP').format(price);
};

// データを取得し、指定されたコールバック関数で処理する共通関数
function fetchData(url, callback) {
  fetch(url)
  .then(response => response.json())
  .then(data => callback(data))
  .catch(error => console.error('Fetching data error:', error));
}

// DOMが完全にロードされたらbodyに'open'クラスを追加
document.addEventListener("DOMContentLoaded", () => {
  if(BODY[0].classList.contains('open')) return
  const container = document.getElementById('products');
  const newsArea = document.querySelector('.js-news-jp');
  const test = document.getElementById('test');
  const AudioPlayer = document.querySelectorAll('.audio-player');

  if(!newsArea) scrollBar()
  if(AudioPlayer.length !== 0) AudioPlayer.forEach(initializePlayer);
  customTransitionFunc();
  hbgFunc()
  originalImage();
  // ページ内の特定のクラスを持つすべてのdiv要素を取得してプレーヤーを初期化

/*  MicroModal.init();

  let closeButton = document.getElementById('js-close');
  let openButton = document.getElementById('js-open');
  if(closeButton){
    closeButton.addEventListener('click', function() {
      console.log(closeButton)
      MicroModal.close('modal-1');
      setCookie('closed', 'true', 30);
    })
    openButton.addEventListener('click', function() {
      MicroModal.show('modal-1');
    })
  }*/


  if (container) {
    fetchData('../json/data.json', data => {
      buildHtml(data, container);
    });
  }


  if (newsArea) {
    fetchData(test ? '../json/test_echo.json' : './json/echo.json', data => {
      buildNews(data, newsArea);
      scrollBar();
    });
  }


  BODY[0].classList.add('open');
  // console.log("DOMContentLoaded")
});



function setCookie(name, value, minutes) {
  var expires = "";
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// クッキーを取得する関数
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i];
    while (c.charAt(0)==' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function TimerDisplayFunc (){
  const targets = document.querySelectorAll('.js-TimerDisplay')
  if(!targets) return

  const TimerDisplay = (target) =>{
    axios.head(window.location.href).then(res => {
      const serverTime = new Date(res.headers.date); // サーバー時刻
      console.log(serverTime); // デバッグのためにサーバー時刻をログ出力

      const now = serverTime.getTime();
      // const now = new Date().getTime();
      const start = new Date(target.dataset.start).getTime();
      const end = new Date(target.dataset.end).getTime();
      let flag = false;


      const checkerStart = () => {
        if (now > start) {
          return true;
        } else {
          return false;
        }
      };

      const checkerEnd = () => {
        if (now < end) {
          return true;
        } else {
          return false;
        }
      };

      if (start && end) {
        if (checkerStart() && checkerEnd()) flag = true;
      } else if (start && !end) {
        if (checkerStart()) flag = true;
      } else if (!start && end) {
        if (checkerEnd()) flag = true;
      } else {
        flag = false;
      }


      if (!flag) target.setAttribute('style', 'display: none;');

    }).catch(error => console.error('サーバー時刻の取得に失敗しました。', error));
  }


  targets.forEach((target)=>{
    TimerDisplay(target)
  })


  // }).catch(error => console.error('サーバー時刻の取得に失敗しました。', error));
}


/*if (document.readyState === 'loading'){
  document.addEventListener("DOMContentLoaded", onContentLoaded);
} else {
  onContentLoaded();
}*/

/*window.addEventListener('pageshow', () => {
  if(performance.getEntriesByType("navigation")[0].type === 'back_forward'){
    BODY[0].classList.add('open');
    scrollBar()
    customTransitionFunc();
    hbgFunc()
    console.log('back_forward')
  }
});*/


document.addEventListener('DOMContentLoaded', () => {
      TimerDisplayFunc()
      SwiperFunc('kv',0)
      SwiperFunc('production',0)
      SwiperFunc('catalog',0)
    }
);

// ウィンドウがロードされたら高さを揃える
window.addEventListener("load", () => {

  // クッキーが存在する場合は再表示しない
  if (!getCookie('closed')) {
    // MicroModal.show('modal-1');
    // document.getElementById('popup').style.display = 'none';
  }
  // matchHeightFunc(false, 1200);
});

// 画像の不透明度を設定する関数
const imageTransparentFunction = (opacity) => {
  const images = document.getElementsByTagName('img');
  Array.from(images).forEach((image) => {
    image.style.opacity = opacity;
  });
};


// キーアップイベントリスナーで画像の不透明度をリセットし、クラスを削除
window.addEventListener("keyup", (e) => {
  const specialKeys = [16, 17, 18, 44, 91, 92, 224];
  if (specialKeys.includes(e.keyCode)) {
    imageTransparentFunction(1);
    BODY[0].classList.remove("no-keyboard");
  }
});

// キーダウンイベントリスナーで画像の不透明度を減らし、クラスを追加
window.addEventListener("keydown", (e) => {
  const specialKeys = [16, 17, 18, 44, 91, 92, 224];
  if (specialKeys.includes(e.keyCode)) {
    imageTransparentFunction(0.1);
    BODY[0].classList.add("no-keyboard");
  }
});
